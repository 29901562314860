import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import Hero from '../components/hero'
import { useTranslation } from 'react-i18next'

export const pageQuery = graphql`
  query NewsDetailQuery($id: String!, $language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    news(id: { eq: $id }) {
      id
      content
      title
      languages {
        locale
        column_name
        value
      }
      remoteImage {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
      updated_at(fromNow: true, locale: $language)
    }
  }
`

interface NewsDetailPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        siteUrl: string
      }
    }
    news: {
      id: string
      title: string
      languages: [
        {
          column_name: string
          locale: string
          value: string
        }
      ]
      remoteImage: IGatsbyImageData
      content: string
      updated_at: string
    }
  }
  pageContext: {
    language: string
  }
}

const NewsDetailPage = (props: NewsDetailPageProps) => {
  const { t } = useTranslation()
  const { language } = props.pageContext
  const post = props.data.news
  const postImg = getImage(post.remoteImage)

  return (
    <>
      <SEO title="Blog" />

      <Hero type="investor">
        <h1 className="uk-heading-small">News</h1>
        <p>Redefining your customer experiences with Jasnita CX solutions.</p>
      </Hero>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <div className="uk-width-expand@m">
              <section key={post.id} className="uk-section-small">
                <h2>
                  {post.languages.length > 0
                    ? post.languages.map((translation) => {
                        if (translation.locale === language && translation.column_name === `title`) {
                          {
                            return translation.value
                          }
                        }
                      })
                    : post.title}
                </h2>
                <div className="uk-child-width-expand" uk-grid="">
                  {post.remoteImage && postImg && (
                    <GatsbyImage
                      className="uk-width-1-1 uk-first-column"
                      imgStyle={{ left: `auto`, right: `auto`, borderRadius: `10px` }}
                      style={{ borderRadius: '10px' }}
                      image={postImg}
                      alt={post.title}
                    />
                  )}
                  <div>
                    <div>
                      {post.languages.length > 0 ? (
                        post.languages.map((translation, index) => {
                          if (translation.locale === language && translation.column_name === `content`) {
                            {
                              return <div key={index} dangerouslySetInnerHTML={{ __html: translation.value }}></div>
                            }
                          }
                        })
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                      )}
                    </div>
                    <p className="uk-article-meta">
                      {t('Written on')} {post.updated_at}.
                    </p>
                  </div>
                </div>
              </section>
            </div>

            <aside className="uk-width-1-3@m">
              <div className="uk-card uk-card-default uk-card-body uk-width-1-1">
                <div>
                  <form>
                    <h4>{t('Newsletter')}</h4>
                    <fieldset className="uk-fieldset">
                      <p>{t('Subscribe to receive our updates')}</p>
                      <div className="uk-margin">
                        <input className="uk-input" type="email" placeholder={t('Enter your email')} />
                      </div>
                      <div className="uk-margin">
                        <button className="uk-button uk-button-primary">{t('Subscribe')}</button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewsDetailPage
